body {
  padding: 0vh;
  margin: 0vh;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}
